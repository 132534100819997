/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  Box, Stack, Button, Grid, Tooltip,
} from '@mui/material';
import axios from 'axios';
import type { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import SelectInput from 'shared-components/inputs/SelectInput';
import type { SelectChangeEvent } from '@mui/material/Select';
import './_dragDrop.scss';
import StradaLoader from 'shared-components/components/StradaLoader';
import type { RootState } from 'mainStore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import HookTextField from 'shared-components/hooks/HookTextField';
import type { IFormValues } from 'formsTypes';
import type { ISuccessResponse } from 'admin/AdminFormTypes';
import type { IAllListResponse } from '../../buildingSettings/leaseAbstraction/types';
import type {
  Data,
} from '../types';

const defaultValues: { name: string; type: string } = {
  name: '',
  type: '',
};

const schema = yup.object().shape({});

const editSchema = yup.object().shape({
  name: yup.string().required('This field is required'),
});

export default function DragDrop(): JSX.Element {
  const leaseData = useLocation().state as Data;
  const { type } = useParams();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [pdfFile, setpdfFile] = useState<File[]>([]);
  const [pdfName, setPdfName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<number>(0);

  const currentWorkspace = useSelector((state: RootState) => state.workspaces.currentWorkspace.currentWorkspace);

  const toArray = (data: File[]): File[] => Array.prototype.slice.call(data);
  const maxSize = 300000000;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/pdf': ['.png', '.jpg', '.jpeg', '.pdf'],
    },
    maxSize: 30000000,
    // maxFiles: 30,
    multiple: true,
    onDrop: (accepted: File[]) => {
      const acceptedSize = accepted.reduce((acc, file) => acc + file.size, 0);
      const pdfFileSize = pdfFile.length > 0 ? pdfFile.reduce((acc, file) => acc + file.size, 0) : 0;
      const totalSize = acceptedSize + pdfFileSize;

      if (totalSize <= maxSize) {
        const fileLists = toArray(accepted);
        if (fileLists.length + pdfFile.length <= 30) {
          const data = new FormData();
          fileLists.forEach((file) => {
            data.append('file', file);
          });
          setpdfFile([...pdfFile, ...accepted]);
        } else {
          enqueueSnackbar('Max file count exceeded', {
            variant: 'error',
            content: (key, message) => (
              <div className='text-white bg-danger ps-4 pe-5 py-3'>
                {message}
              </div>
            ),
          });
        }
      } else {
        enqueueSnackbar('Max Bulk size exceeded', {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>
              {message}
            </div>
          ),
        });
      }
    },
  });

  const handleFileDelete: (index: number) => void = (index) => {
    const newFiles = [...pdfFile];
    newFiles.splice(index, 1);
    setpdfFile(newFiles);
  };

  const { data: templateList = [] } = useQuery('get/lease-template', async () => axios({
    url: `/api/filter/lease-templates/?workspace=${currentWorkspace.id}`,
    method: 'GET',
  }), {
    select: (res: AxiosResponse<IAllListResponse>) => {
      let options = res.data.detail.results.map((item) => ({
        name: item.template_name,
        value: item.id,
      }));

      const defaultTemplate = { name: 'Default Template', value: 0 };

      if (options.length > 0) {
        options.unshift(defaultTemplate);
      } else {
        options = [defaultTemplate];
      }

      return options;
    },
  });

  const { mutate: uploadFiles, isLoading: uploading } = useMutation(async (data: FormData) => axios({
    url: type === 'edit' ? `/api/lease/${leaseData.id}/` : '/api/lease/',
    method: type === 'edit' ? 'put' : 'post',
    data,
  }), {
    onSuccess: (res: AxiosResponse<ISuccessResponse>) => {
      enqueueSnackbar(res.data.detail.message);
      setSelectedTemplate(0);
      navigate('/workspace/lease');
    },
    onError: () => {
      enqueueSnackbar('Upload Failed!', {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });

  const {
    control, formState, watch, reset, setValue,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(type === 'edit' ? editSchema : schema),
  });
  const { errors } = formState;

  const checkDisabled = (): boolean => {
    let disabled = false;
    if (type === 'edit') {
      if ((pdfFile.length === 0 && pdfName === '') || !watch('name')) {
        disabled = true;
      }
    } else if (pdfFile.length === 0 && pdfName === '') {
      disabled = true;
    }
    return disabled;
  };

  const onAddFiles: () => void = () => {
    const formData = new FormData();
    formData.append('workspace', currentWorkspace.id.toString());
    if (type === 'edit') {
      formData.append('tenant_name', watch('name'));
    }
    formData.append('template', selectedTemplate.toString());

    if (pdfFile.length !== 0) {
      if (type === 'edit') {
        formData.append('pdf', pdfFile[0], pdfFile[0].name);
      } else {
        pdfFile.forEach((file) => {
          formData.append('pdf', file);
        });
      }
    }
    uploadFiles(formData);
  };

  React.useEffect(() => {
    if (type === 'edit' && leaseData !== undefined) {
      setValue('name', leaseData?.tenant_name, { shouldDirty: true });
      if (leaseData.template === null && leaseData.lease_type === 'default_lease') {
        setSelectedTemplate(0);
      } else {
        setSelectedTemplate(leaseData?.template);
      }
      setPdfName(leaseData?.pdf?.split('/')?.reverse()[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, leaseData]);

  return (
    <Stack className='lease-file-uplaod new-lease'>
      <div className='main-area'>
        <StradaLoader open={uploading} message='Uploading' />
        <section className='drap-section-cois mt-2'>
          <div className='header'>
            <ArrowBackIcon className='back-icon' onClick={(): void => { window.history.back(); }} />
            <p>{type === 'edit' ? 'Edit lease' : 'Upload New Lease'}</p>

          </div>

          <form>
            <Grid container columnSpacing={2} mt={2}>

              {type === 'edit' && (
                <Grid item sm={12}>
                  <HookTextField
                    name='name'
                    label='Tenant Name'
                    control={control}
                    errors={errors}
                    maxLength={100}
                  />
                </Grid>
              ) }

              <Grid item sm={12}>
                <SelectInput
                  value={JSON.stringify(selectedTemplate)}
                  name='type'
                  label='Type of lease report'
                  onChange={(obj: SelectChangeEvent): void => { setSelectedTemplate(Number(obj.target.value)); }}
                  options={templateList}
                  showPleaseSelect={false}
                />
              </Grid>

            </Grid>

          </form>
          {type === 'edit' ? (pdfFile.length === 0 && pdfName === '') && (
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Box className='preview-container-lease d-flex' sx={{ background: 'transparent', justifyContent: 'center', height: '8rem !important' }}>
                Drag and drop a document here or click
                <div className='cursor-pointer'>
                  <Tooltip title={(
                    <>
                      max single file size: 30 MB
                      <br />
                    </>
                  )}
                  >
                    <InfoIcon className='back-icon ' />
                  </Tooltip>
                </div>
                <br />
              </Box>
            </div>
          ) : (
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <Box className='preview-container-lease d-flex' sx={{ background: 'transparent', justifyContent: 'center', height: '8rem !important' }}>
                Drag and drop a document here or click
                <div className='cursor-pointer'>
                  <Tooltip title={(
                    <>
                      max single file size: 30 MB
                      <br />
                      max total size: 300 MB
                      <br />
                      max no. of files: 30
                      <br />
                    </>
                  )}
                  >
                    <InfoIcon className='back-icon ' />
                  </Tooltip>
                </div>
              </Box>
            </div>
          )}
        </section>
        { type === 'edit' ? (pdfFile.length > 0 || pdfName) && (
          <div className='input-wrap-lease mb-6'>
            <div className='rows p-0'>
              <InsertDriveFileIcon fontSize='large' />
              <div className='attachment'>
                <div className='attachment-name'>{pdfFile[0]?.name || pdfName}</div>

              </div>
              <div className='control-area cursor-pointer'>
                <DeleteIcon className='delete-icon' onClick={(): void => { setpdfFile([]); setPdfName(''); }} />

              </div>
            </div>
          </div>
        ) : pdfFile.length > 0 && pdfFile.map((file, index) => (
          <div className='input-wrap-lease mb-6' key={`${Math.random()} + ${file.lastModified}`}>
            <div className='rows p-0'>
              <InsertDriveFileIcon fontSize='large' />
              <div className='attachment'>
                <div className='attachment-name'>{file?.name}</div>

              </div>
              <div className='control-area cursor-pointer'>
                <DeleteIcon className='delete-icon' onClick={(): void => { handleFileDelete(index); }} />

              </div>
            </div>
          </div>
        ))}

        <div className='add-lease'>
          <Button
            className='text-transform-none btn'
            onClick={(): void => {
              setpdfFile([]);
              reset();
              setPdfName('');
              window.history.back();
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={onAddFiles}
            className='durdate-save-btn'
            style={{
              textTransform: 'inherit', width: 'fit-content', color: checkDisabled() ? 'rgba(33, 33, 33, 0.38)' : 'white', background: checkDisabled() ? '#E4E4E4' : '#00CFA1', cursor: checkDisabled() ? 'no-drop' : 'pointer', fontSize: '12px', borderRadius: '8px',
            }}
            variant='contained'
            disabled={checkDisabled()}
          >
            Upload
            {' '}
          </Button>
        </div>
      </div>

    </Stack>
  );
}
