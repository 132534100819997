/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useState, useMemo } from 'react';
import StradaSearch from 'admin/buildingSection/budget-calendar/components/StradaSearch';
import CustomTable from 'shared-components/tables/CustomTable';
import type { IDataObject } from 'formsTypes';
import type { Cell } from 'react-table';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCommentIcon from '@mui/icons-material/AddComment';
import './_autoRep.scss';
import {
  Button, Dialog, DialogActions, DialogTitle, ClickAwayListener,

} from '@mui/material';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import CustomLoader from 'shared-components/components/CustomLoader';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import type { Ires, Iresponse, Ipayload } from './types';
import CreateDialog from './CreateDialog';

function AutoReplies(): JSX.Element {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState(1);
  const [search, setSearch] = useState('');
  const [sentSearch, setSentSearch] = useState('');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [scheduledFollowUps, setScheduledFollowUps] = useState<Ires[]>([]);
  const [sentFollowUps, setSentFollowUps] = useState<Ires[]>([]);
  const [cursorScheduled, setCursorScheduled] = useState<string | null | undefined>(null);
  const [getNextDataScheduled, setgetNextDataScheduled] = useState<boolean>(false);
  const [cursorSent, setCursorSent] = useState<string | null | undefined>(null);
  const [getNextDataSent, setgetNextDataSent] = useState<boolean>(false);
  const [editMsg, setEditMsg] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [rowToEdit, setRowToEdit] = useState<number>(-1);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<Ipayload>();
  const [showMore, setShowMore] = useState<number>(-1);
  const [selectedMsgInfo, setSelectedMsgInfo] = useState<Ipayload>();

  const tabs = [
    {
      id: 1,
      heading: 'Scheduled',
    },
    {
      id: 2,
      heading: 'Sent',
    },
  ];

  React.useEffect(() => {
    if (activeTab === 2) {
      setSentSearch(search);
    }
  }, [search, activeTab]);

  // get schedule follow ups list
  const {
    data: scheduledFollowUpsData, isLoading: scheduledLoading, isIdle, isFetching,
  } = useQuery(
    ['scheduled-follow-ups', search],
    async () => axios({
      url: '/api/dashboard/automatic-replies/',
      method: 'get',
      params: {
        search,
      },
    }),

    {
      enabled: activeTab === 1,
      select: (res: AxiosResponse<Iresponse>) => res.data,
      onSuccess: (res) => {
        setScheduledFollowUps(res.results);
        setCursorScheduled(res.next?.split('page=')[1]?.split('?')[0]);
      },
    },
  );

  const { data: loadMoreDataScheduled, isLoading: loadMoreCursorScheduled } = useQuery(
    ['get-loadmore-schedule', cursorScheduled],
    async () => axios({
      url: `/api/dashboard/automatic-replies/?page=${cursorScheduled}`,
      method: 'GET',
    }),
    {
      enabled: getNextDataScheduled && cursorScheduled !== null,
      select: (res: AxiosResponse<Iresponse>) => res.data,

    },
  );
  const GetNextCursorDataScheduled = (): void => {
    if (scheduledFollowUpsData?.next !== null) {
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      loadMoreDataScheduled && loadMoreDataScheduled.next !== null ? setCursorScheduled(loadMoreDataScheduled.next.split('page=')[1]?.split('?')[0]) : '';
      setgetNextDataScheduled(true);
    } else {
      setgetNextDataScheduled(false);
    }
  };

  React.useEffect(() => {
    if (getNextDataScheduled && cursorScheduled !== null && loadMoreDataScheduled !== undefined) {
      setScheduledFollowUps([...scheduledFollowUps, ...loadMoreDataScheduled.results]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreDataScheduled]);

  // edit scheduled message
  const { mutate: editScheduledMsg, isLoading: editLoading } = useMutation(async (dataArg: Ipayload) => axios({
    url: '/api/dashboard/automatic-replies/update-scheduled/',
    method: 'post',
    data: { ...dataArg, message },
  }), {
    // eslint-disable-next-line @typescript-eslint/require-await
    onSuccess: async () => {
      await queryClient.invalidateQueries('scheduled-follow-ups').catch()
        .then();
      enqueueSnackbar('Updated Successfully.');
      setMessage('');
      setEditMsg('');
      setRowToEdit(-1);
      setIsEditing(false);
    },
    onError: (): void => {
      setMessage('');
      setEditMsg('');
      setRowToEdit(-1);
      setIsEditing(false);
      enqueueSnackbar('Updation failed.', {
        variant: 'error',
        content: (key, msg) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {msg}
          </div>
        ),
      });
    },
  });

  // edit scheduled event
  const { mutate: deleteScheduled } = useMutation(async (dataArg: Ipayload) => axios({
    url: '/api/dashboard/automatic-replies/delete-scheduled-event/',
    method: 'post',
    data: { ...dataArg },
  }), {
    // eslint-disable-next-line @typescript-eslint/require-await
    onSuccess: async () => {
      await queryClient.invalidateQueries('scheduled-follow-ups').catch()
        .then();
      enqueueSnackbar('Deleted Successfully.');
      setRowToDelete(undefined);
      setDeleteDialog(false);
    },
    onError: (): void => {
      setRowToDelete(undefined);
      setDeleteDialog(false);
      enqueueSnackbar('Deletetion failed.', {
        variant: 'error',
        content: (key, msg) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {msg}
          </div>
        ),
      });
    },
  });

  // get sent follow ups list
  const { data: sentFollowUpsData, isLoading: sentLoading, isFetching: sentIsFetching } = useQuery(
    ['sent-follow-ups', sentSearch],
    async () => axios({
      url: 'api/dashboard/automatic-replies/sent-follow-ups/',
      method: 'get',
      params: { search: sentSearch },
    }),
    {
      enabled: activeTab === 2,
      select: (res: AxiosResponse<Iresponse>) => res.data,
      onSuccess: (res) => {
        setSentFollowUps(res.results);
        setCursorSent(res.next?.split('page=')[1]?.split('?')[0]);
      },
    },
  );

  const { data: loadMoreDataSent, isLoading: loadMoreCursorSent } = useQuery(
    ['get-loadmore-sent', cursorSent],
    async () => axios({
      url: `api/dashboard/automatic-replies/sent-follow-ups/?page=${cursorSent}`,
      method: 'GET',
    }),
    {
      enabled: getNextDataSent && cursorSent !== null,
      select: (res: AxiosResponse<Iresponse>) => res.data,

    },
  );

  React.useEffect(() => {
    if (getNextDataSent && cursorSent !== null && loadMoreDataSent !== undefined) {
      setSentFollowUps([...sentFollowUps, ...loadMoreDataSent.results]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreDataSent]);

  const GetNextCursorDataSent = (): void => {
    if (sentFollowUpsData?.next !== null) {
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      loadMoreDataSent && loadMoreDataSent.next !== null ? setCursorSent(loadMoreDataSent.next.split('page=')[1]?.split('?')[0]) : '';
      setgetNextDataSent(true);
    } else {
      setgetNextDataSent(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Event',
        accessor: 'event_name',
        width: '15%',
        isSorted: false,
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;

          return (
            <div className={isEditing ? 'blur-cell cell-padding' : 'cell-padding event-name'}>
              {row.values.event_name}
            </div>
          );
        },
      },
      {
        Header: 'Vendor',
        accessor: 'vendors',
        isSorted: false,
        width: '25%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const { original } = row;
          const to = original.vendors.map((item: { full_name: string }) => item.full_name).join(', ');
          const cc = original.vendor_cc.join(', ');
          const bcc = original.vendor_bcc.join(', ');
          return (
            <div
              className={isEditing ? 'blur-cell cell-padding vendor-wrapper' : 'vendor-wrapper cell-padding'}
            >
              <p className='vendor-to'>
                To:
                {' '}
                {to}
              </p>
              <p>
                CC:
                {' '}
                {cc}
              </p>
              <p>
                Bcc:
                {' '}
                {bcc}
              </p>

            </div>
          );
        },
      },
      {
        Header: 'Message',
        accessor: 'message',
        isSorted: false,
        width: '38%',

        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const { original } = row;
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const { message } = row.values;
          const truncatedMessage = message.length > 470 ? `${message.substring(0, 470)}...` : message;

          return (
            <div className={isEditing && Number(row.id) !== rowToEdit ? 'blur-cell cell-padding' : isEditing && Number(row.id) === rowToEdit ? '' : 'cell-padding'} style={{ height: '100%', width: '100%' }} id={`${original.rfp_id}`}>
              { rowToEdit === Number(row.id) ? (
                <ClickAwayListener
                  onClickAway={(): void => {
                  }}
                >
                  <div className='d-flex align-items-start text-area-wrapper'>
                    <textarea
                      className='edit-textarea'
                      defaultValue={editMsg}
                      onMouseLeave={(e: React.MouseEvent<HTMLTextAreaElement>): void => {
                        const valueOnMouseLeave = e.currentTarget.value;
                        setMessage(valueOnMouseLeave);
                        setEditMsg(valueOnMouseLeave);
                      }}
                      rows={7}
                    />
                    <div className='mt-1 mb-3 d-flex justify-content-center align-items-center icon-btn'>
                      <CreateDialog
                        selectedMsgInfo={selectedMsgInfo}
                        setEditMsg={setEditMsg}
                        setMessage={setMessage}
                      />
                    </div>
                  </div>
                </ClickAwayListener>

              )

                : (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                  <p onClick={(): void => {
                    showMore === Number(row.id) ? setShowMore(-1) : setShowMore(Number(row.id));
                  }}
                  >
                    {showMore === Number(row.id) ? message : truncatedMessage}
                  </p>
                ) }
              {' '}

            </div>
          );
        },
      },
      {
        Header: activeTab === 1 ? 'Scheduled' : 'Sent',
        accessor: 'date',
        width: '10%',
        isSorted: true,
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const date = typeof row.values.date === 'string' ? row.values.date : '';
          const scheduleDate = new Date(date);
          const originalDateString = scheduleDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
          return (
            <div className={isEditing ? 'blur-cell cell-padding' : 'cell-padding'}>{originalDateString}</div>
          );
        },
      },
      {
        Header: '',
        accessor: 'options',
        width: '12%',
        isSorted: false,
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { row } = cell;
          const { original } = row;
          return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              { rowToEdit === Number(row.id)
                ? (
                  <div
                    className='icons-wrapper d-flex justify-content-end'
                  >
                    <DoneIcon onClick={(): void => {
                      if (message === '') {
                        enqueueSnackbar('Message cannot be empty.', {
                          variant: 'error',
                          content: (key, msg) => (
                            <div className='text-white bg-danger ps-4 pe-5 py-3'>
                              {msg}
                            </div>
                          ),
                        });
                        setMessage('');
                        setEditMsg('');
                        setRowToEdit(-1);
                        setIsEditing(false);
                      } else {
                        editScheduledMsg({
                          reminder_id: original.reminder_id,
                          reminder_of: original.reminder_of,
                        });
                      }
                    }}
                    />
                    <CancelIcon onClick={(): void => {
                      setMessage('');
                      setEditMsg('');
                      setRowToEdit(-1);
                      setIsEditing(false);
                    }}
                    />
                  </div>
                )
                : (
                  <div
                    className={isEditing ? 'blur-cell icons-wrapper' : 'icons-wrapper cell-padding'}
                  >
                    <ModeEditIcon onClick={(): void => {
                      setRowToEdit(Number(row.id)); setEditMsg(original.message); setIsEditing(true); setMessage(original.message);
                      setSelectedMsgInfo({
                        reminder_id: original.reminder_id,
                        reminder_of: original.reminder_of,
                      });
                    }}
                    />
                    <AddCommentIcon />
                    <DeleteIcon onClick={(): void => {
                      setRowToDelete({
                        reminder_id: original.reminder_id,
                        reminder_of: original.reminder_of,
                      }); setDeleteDialog(true);
                    }}
                    />
                  </div>
                )}
            </>

          );
        },
      },

    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMsg, rowToEdit, isEditing, activeTab, showMore, message],
  );
  const sentTable = columns.slice(0, -1);
  return (
    <div className='auto-rep-wrapper'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          {tabs.map((tab): JSX.Element => (
            <div
              key={tab.id}
              className={activeTab === tab.id ? 'single-tab active' : 'single-tab'}
              aria-hidden='true'
              onClick={(): void => {
                setActiveTab(tab.id);
                setSentSearch('');
                setSearch('');
                setShowMore(-1);
                setMessage('');
                setEditMsg('');
                setRowToEdit(-1);
                setIsEditing(false);
              }}
            >
              {tab.heading}
            </div>
          ))}

        </div>
        <div className='search-wrapper'>
          <StradaSearch
            value={search}
            setSearch={setSearch}
            placeholder='Search'
          />
        </div>
      </div>
      <div className='divider' />

      {activeTab === 1
        ? !isFetching && scheduledFollowUps.length > 0 ? (
          <CustomTable
            {...{
              columns,
              data: scheduledFollowUps.length > 0 ? scheduledFollowUps : [],
            }}
          />
        ) : (
          <div className='empty-array-wrapper' style={{ marginTop: '8rem' }}>
            {!isFetching && <p className='header'>No Scheduled  Follow Ups</p>}
          </div>
        )

        : !sentIsFetching && sentFollowUps.length > 0 ? (
          <CustomTable
            columns={[...sentTable]}
            {...{
              sentTable,
              data: sentFollowUps.length > 0 ? sentFollowUps : [],
            }}
          />
        ) : (
          <div className='empty-array-wrapper' style={{ marginTop: '8rem' }}>
            {!sentIsFetching && <p className='header'>No Sent Follow Ups</p>}
          </div>
        )}

      {(sentIsFetching || isFetching || scheduledLoading || sentLoading || loadMoreCursorScheduled || loadMoreCursorSent || editLoading) && <div className='loader-wrapper'><CustomLoader /></div> }

      <div className='mytasks-load-more'>
        {activeTab === 1 && scheduledFollowUps.length > 0 && !isFetching && !isIdle && !loadMoreCursorScheduled && !scheduledLoading && loadMoreDataScheduled?.next !== null && scheduledFollowUpsData?.next !== null && <p aria-hidden='true' onClick={(): void => { GetNextCursorDataScheduled(); }}> Load more </p>}
        {activeTab === 2 && sentFollowUps.length > 0 && !sentIsFetching && !loadMoreCursorSent && !sentLoading && loadMoreDataSent?.next !== null && sentFollowUpsData?.next !== null && <p aria-hidden='true' onClick={(): void => { GetNextCursorDataSent(); }}> Load more </p>}
      </div>
      {/* Delete confirmation dialog */}
      <Dialog
        open={deleteDialog}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          elevation: 0,
          style: {
            width: '600px',
            paddingBottom: '.5rem',
            paddingRight: '.7rem',
          },
        }}
      >
        <DialogTitle>
          <div>
            <p style={{ fontSize: '20px', color: 'rgba(33, 33, 33, 0.87)' }}>Are you sure you want to delete this follow up? </p>
          </div>
        </DialogTitle>
        <DialogActions>
          <Button
            className='text-transform-none'
            style={{ color: '#00CFA1', lineHeight: '1.7rem' }}
            onClick={(): void => { setRowToDelete(undefined); setDeleteDialog(false); }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            className='ms-2 text-transform-none bg-danger'
            style={{ color: 'white', padding: '6px 8px' }}
            onClick={(): void => { rowToDelete ? deleteScheduled(rowToDelete) : ''; setDeleteDialog(false); }}
          >
            Delete
            {' '}

          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default AutoReplies;
