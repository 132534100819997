/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {
  useMemo, useState, useEffect,
} from 'react';
import {
  Avatar, Button, AvatarGroup, Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import './_myChecklistTask.scss';
import MyChecklistTable from 'shared-components/tables/MyChecklistTable';
import type { Cell } from 'react-table';
import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import type { IDataObject } from 'formsTypes';
import { useNavigate } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import CustomLoader from 'shared-components/components/CustomLoader';
import Switch from 'shared-components/inputs/Switch';
import Filters from './Filters';
import type {
  Iresponse, Iassignees, Iresults,
} from './types';
import ChecklistSingleTaskDetail from './ChecklistSingleTaskDetail';

interface ITaskComplete {
  checklistStatus: boolean;
  completeId: number;
}
interface Idata {
  detail: string[];
}
interface IErrorResponse {
  data: Idata;
}

function MoveIcon(): JSX.Element {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11.5156 0H18.5V6.98438H16.4844V3.42188L6.6875 13.2188L5.28125 11.8125L15.0781 2.01562H11.5156V0ZM16.4844 15.9844V9H18.5V15.9844C18.5 16.5156 18.2969 16.9844 17.8906 17.3906C17.4844 17.7969 17.0156 18 16.4844 18H2.51562C1.95312 18 1.46875 17.8125 1.0625 17.4375C0.6875 17.0312 0.5 16.5469 0.5 15.9844V2.01562C0.5 1.45312 0.6875 0.984375 1.0625 0.609375C1.46875 0.203125 1.95312 0 2.51562 0H9.5V2.01562H2.51562V15.9844H16.4844Z' fill='#212121' fillOpacity='0.6' />
    </svg>
  );
}
function NoTask(): JSX.Element {
  return (
    <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='52' height='52' rx='12' fill='#E4E4E4' />
      <path d='M28.0156 16.0156H20C19.4375 16.0156 18.9688 16.2188 18.5938 16.625C18.2188 17 18.0312 17.4531 18.0312 17.9844L17.9844 34.0156C17.9844 34.5469 18.1719 35.0156 18.5469 35.4219C18.9531 35.7969 19.4375 35.9844 20 35.9844H32C32.5625 35.9844 33.0312 35.7969 33.4062 35.4219C33.8125 35.0156 34.0156 34.5469 34.0156 34.0156V22.0156L28.0156 16.0156ZM24.9219 32L21.4062 28.4375L22.8125 27.0312L24.9219 29.1875L29.1875 24.9219L30.5938 26.3281L24.9219 32ZM26.9844 23V17.5156L32.5156 23H26.9844Z' fill='#212121' fillOpacity='0.87' />
    </svg>
  );
}
function CloseIcon(): JSX.Element {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.9844 1.42188L8.40625 7L13.9844 12.5781L12.5781 13.9844L7 8.40625L1.42188 13.9844L0.015625 12.5781L5.59375 7L0.015625 1.42188L1.42188 0.015625L7 5.59375L12.5781 0.015625L13.9844 1.42188Z' fill='#212121' fillOpacity='0.6' />
    </svg>

  );
}

export default function MyChecklistTasks(): JSX.Element {
  const [filteredData, setFilteredData] = useState<Iresults[]>([]);
  const [workspaceFilter, setWorkspaceFilter] = useState<number[]>([]);
  const [buildingFilter, setBuildingFilter] = useState<number[]>([]);
  const [showIncomplete, setShowIncomplete] = useState<boolean>(true);
  const [getNextData, setgetNextData] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string | null | undefined>(null);
  const [focusedTask, setFocusedTask] = useState<IDataObject | Iresults>();
  const [focusedTaskId, setFocusedTaskId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: allTasks = { next: null, previous: null, results: [] },
    isLoading: loadMore, isFetching,
  } = useQuery(
    ['get/all-checklist-tasks', workspaceFilter, buildingFilter, showIncomplete],
    async () => axios({
      url: 'api/dashboard/user-checklist-tasks/',
      method: 'get',
      params: {
        property: buildingFilter,
        workspace: workspaceFilter,
        only_incomplete: showIncomplete,
      },
    }),
    {
      select: (res: AxiosResponse<Iresponse>) => res.data,
      onSuccess: (res) => {
        setCursor(res.next?.split('cursor=')[1]?.split('?')[0]);
        setFilteredData(res.results);
        setIsLoading(false);
      },
    },
  );
  const { data: loadMoreData, isLoading: loadMoreCursor } = useQuery(
    ['get/loadmoretasks', cursor],
    async () => axios({
      url: `/api/dashboard/user-checklist-tasks/?cursor=${cursor}`,
      method: 'GET',

    }),
    {
      enabled: getNextData && cursor !== null,
      select: (res: AxiosResponse<Iresponse>) => res.data,

    },
  );
  const GetNextCursorData = (): void => {
    if (allTasks.next !== null) {
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      loadMoreData && loadMoreData.next !== null ? setCursor(loadMoreData.next.split('cursor=')[1]?.split('?')[0]) : '';
      setgetNextData(true);
    } else {
      setgetNextData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const handleRowClick = (data: Iresults): void => {
    setFocusedTask(data);
    setFocusedTaskId(Number(data.id));
  };

  useEffect(() => {
    if (getNextData && cursor !== null && loadMoreData !== undefined) {
      setFilteredData([...filteredData, ...loadMoreData.results]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreData]);

  const { mutate: handleChecklistTaskCompleted } = useMutation(
    async (checklistCompleteStatus: ITaskComplete) => axios({
      url: `/api/checklist-task/${checklistCompleteStatus.completeId}/`,
      method: 'patch',
      data: {
        is_completed: checklistCompleteStatus.checklistStatus,
      },
    }),
    {
      onSuccess: async () => {
        enqueueSnackbar('Task completed');
        await queryClient.invalidateQueries('get/all-checklist-tasks').then();
      },
      onError: (err: IErrorResponse) => {
        enqueueSnackbar(err.data.detail[0] ? err.data.detail[0] : 'Error in Completion of Task', {
          variant: 'error',
          content: (key, message) => (
            <div className='text-white bg-danger ps-4 pe-5 py-3'>
              {message}
            </div>
          ),
        });
      },
    },
  );
  const handleTaskComplete = (result: IDataObject): void => {
    if (result.is_disabled.disabled) {
      enqueueSnackbar(`${result.is_disabled.message}`);
    } else {
      handleChecklistTaskCompleted({ checklistStatus: !result.is_completed, completeId: Number(result.id) });
    }
  };
  const handleTaskCompleteClick = (result: IDataObject): void => {
    if (!result.is_completed) {
      handleTaskComplete(result);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
        isSorted: false,
        width: '28%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value, row } = cell;
          const { original } = row;
          const newVal: string = value;
          return (
            <div>
              <p style={{ fontSize: '14px', color: 'rgba(33, 33, 33, 0.87)' }}>
                {newVal}
              </p>
              <p style={{ fontSize: '12px', color: 'rgba(33, 33, 33, 0.6)' }}>{`${original.checklist_name} | ${original.checklist_template_name}`}</p>
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'due_date',
        isSorted: false,
        width: '12%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value } = cell;
          const newVal: string = value;
          return (
            <div style={{
              color:
                moment(newVal)
                  .isAfter(new Date())
                  ? 'rgba(33, 33, 33, 0.87);'
                  : '#c62828',
            }}
            >
              {newVal ? moment(newVal).format('LL') : ''}
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'checklist_building',
        width: '17%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value } = cell;
          return (
            <div className='building-cell'>
              {value.address}
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'checklist_workspace',
        width: '16%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value } = cell;
          return (
            <div className='building-cell'>
              <Avatar sx={{ bgcolor: '#00CFA1' }} variant='rounded'>
                {value.name[0]}
              </Avatar>
              {value.name}
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'assignees',
        width: '4%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value } = cell;
          const newVal: Iassignees[] = value;
          return (
            <AvatarGroup max={1} className='flex-row-reverse' style={{ width: 'fit-content' }}>
              {newVal.map((assignee: Iassignees) => (
                <Avatar key={assignee.id} alt={assignee.name} src={`${process.env.REACT_APP_IMAGE_URL}${assignee.avatar}`} sx={{ height: '28px', width: '28px' }} />
              ))}
            </AvatarGroup>
          );
        },
      },
      {
        Header: '',
        accessor: 'is_completed',
        width: '22%',
        Cell: (cell: Cell<IDataObject>): JSX.Element => {
          const { value, row } = cell;
          const { original } = row;
          const isCompleted: boolean = value;
          return (
            <div className='d-flex flex-row-reverse align-items-center'>
              <Button
                onClick={(e): void => { e.stopPropagation(); handleTaskCompleteClick(original); }}
                className='durdate-save-btn'
                style={{
                  textTransform: 'inherit', width: 'fit-content', color: isCompleted ? 'rgba(33, 33, 33, 0.87)' : 'white', background: isCompleted ? 'white' : '#00CFA1', cursor: isCompleted ? 'no-drop' : 'pointer', fontSize: '12px', border: isCompleted ? '1px solid #E4E4E4 ' : 'none', borderRadius: '8px',
                }}
                startIcon={!isCompleted ? <DoneIcon /> : null}
                variant='contained'
              >
                {isCompleted ? 'Completed' : 'Complete Task'}
              </Button>
              {' '}
              <div
                className='mx-4 cursor-pointer'
                aria-hidden='true'
                onClick={(): void => {
                  navigate(`/workspace/view-checklist/${original.checklist}`, { state: original });
                }}
              >
                <MoveIcon />
              </div>

            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allTasks],
  );

  return (
    <div className='my-checklist-task-wrapper'>
      <div className='pb-3 d-flex align-items-center' style={{ borderBottom: '1px solid #E4E4E4' }}>
        <Filters
          workspaceFilter={workspaceFilter}
          setWorkspaceFilter={setWorkspaceFilter}
          buildingFilter={buildingFilter}
          setBuildingFilter={setBuildingFilter}
        />
        <Switch value={showIncomplete} checked={showIncomplete} onChange={(e, val): void => { setShowIncomplete(val); }} />
        <p className='incomplete-switch'>Only incomplete</p>
      </div>
      {filteredData.length > 0
        ? (
          <>
            <MyChecklistTable
              columns={columns}
              data={filteredData}
              handleRowClick={handleRowClick}
            />
            <div>
              {loadMoreCursor && <div style={{ height: '20vh' }} className='d-flex justify-content-center align-items-center'><CustomLoader /></div>}

              <div className='mytasks-load-more'>
                {!isFetching && !loadMoreCursor && buildingFilter.length === 0 && workspaceFilter.length === 0 && loadMoreData?.next !== null && allTasks.next !== null && <p aria-hidden='true' onClick={(): void => { GetNextCursorData(); }}> Load more </p>}
              </div>
            </div>
          </>
        )
        : (
          <div className='no-task'>
            {loadMore || isLoading
              ? <CustomLoader />
              : (
                <>
                  <NoTask />
                  <h6>All clear!</h6>
                  <p>New tasks will be shown here.</p>
                </>
              )}
          </div>
        )}
      {focusedTaskId !== 0
        && (
          <Dialog
            open={focusedTaskId !== 0}
            fullScreen
            keepMounted
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'
            PaperProps={{
              elevation: 0,
              style: {
                overflow: 'hidden',
                width: '760px',
                height: '80vh',
                borderRadius: '8px',
                boxShadow: '0px 24px 38px 3px rgba(97, 97, 97, 0.14), 0px 9px 46px 8px rgba(97, 97, 97, 0.12), 0px 11px 15px -7px rgba(97, 97, 97, 0.2)',
              },
            }}
          >
            <DialogTitle style={{ borderBottom: '1px solid #E4E4E4', height: '84px', padding: '16px 24px' }}>
              <div className='d-flex justify-space-between align-items-start'>
                <div style={{ maxHeight: '68px', overflow: 'scroll' }}>
                  <p style={{ fontSize: '20px', fontWeight: '500', color: 'rgba(33, 33, 33, 0.87)' }}>
                    {focusedTask?.name}
                  </p>
                  <div style={{ fontSize: '16px', fontWeight: '400', color: '#00CFA1' }}>
                    <span>
                      {focusedTask?.checklist_name}
                      {'  '}
                    </span>
                    <span style={{ color: 'rgba(33, 33, 33, 0.6)' }}>|</span>
                    <span>
                      {'  '}
                      {focusedTask?.checklist_template_name}
                    </span>
                  </div>
                </div>
                <div aria-hidden='true' className='cursor-pointer' onClick={(): void => { setFocusedTaskId(0); }}>
                  <CloseIcon />
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <ChecklistSingleTaskDetail focusedTaskId={focusedTaskId} />
            </DialogContent>
          </Dialog>
        )}
    </div>
  );
}
